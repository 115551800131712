import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function FourthPage(){
	const navigate = useNavigate();

	const [blurState, setBlurState] = useState([5, 5, 5, 5, 5]);

	const handleUnblur = (index) => {
		const newBlurState = [...blurState];
		newBlurState[index] = 0; // Unblur the clicked paragraph
		setBlurState(newBlurState);
	};

	return(
		<div className="App" style={{background: "rgb(255,182,193)"}}>

			<h1 className="title" style={{color: "rgb(22,22,22)", borderBottom: '2px solid red'}}>I was in a relationship and did not know about it!</h1>
			
			<br/>

			<p 
				className="p-container" 
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[0]}px)`}}
				onClick={() => handleUnblur(0)}
			>
				You told me you started counting from that day—the day everything truly began. 
				That realization hit me hard, in the best way possible. It meant that I had unknowingly stepped into a 
				relationship with you, a bond so pure and profound, without even realizing it. How lucky am I that God 
				gave me the appearance, the mind, and the confidence to capture your attention to this extent? The 
				thought fills me with gratitude and amazement, knowing someone as wonderful as you chose to notice me 
				and make me a part of your world.
			</p>

			<br/>

			<p
				className="p-container" 
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[1]}px)`}}
				onClick={() => handleUnblur(1)}
			>
				The next morning was a typical, dull Monday, the kind we all dread. But this one felt 
				even heavier because I knew you had 10 grueling hours of classes ahead of you, which meant I 
				wouldn’t get to see you much that day. At 9 o’clock, I walked with you and Aggeliki for a while, 
				sharing those fleeting moments before parting ways. As I left to catch my bus, I felt the usual 
				pang of longing. Waiting at the stop, trying to pass the time, I mindlessly opened Instagram, 
				hoping for some distraction in the form of reels.
			</p>

			<br/>

			<p
				className="p-container" 
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[2]}px)`}}
				onClick={() => handleUnblur(2)}
			>
				But instead of reels, I saw something that took my breath away—a message from you. "Σπύρο, 
				θες να πάμε βόλτα?" My heart skipped a beat. Despite your exhausting day ahead, filled with back-to-back 
				classes, you still wanted to spend time with me. You wanted to go for a walk with me. It was such a 
				simple gesture, yet it meant the world to me. It was the first time anyone had done something like 
				that for me—prioritized me, made me feel so important, even when their own day was anything but easy.
			</p>

			<br/>

			<p
				className="p-container" 
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[3]}px)`}}
				onClick={() => handleUnblur(3)}
			>
				That message wasn’t just an invitation for a walk; it was a glimpse into how much I mattered to you. I 
				couldn’t help but feel overwhelmed with emotions—joy, disbelief, and a profound sense of appreciation. 
				In that moment, I realized how lucky I was, not just because of your beauty or charm, but because of the 
				heart you carry inside you. The thought of you choosing to carve out time for me, even on your most 
				hectic day, makes me feel cherished in ways words can barely describe.
			</p>

			<br/>

			<p
				className="p-container" 
				style={{color: "rgb(22, 22, 22)", filter: `blur(${blurState[4]}px)`}}
				onClick={() => handleUnblur(4)}
			>
				Looking back, that moment solidified something for me. You weren’t just someone I admired 
				from afar or enjoyed spending time with; you were someone who made my world brighter, who saw 
				value in the little things we shared. That message—those few words—will forever stay etched in 
				my memory, a reminder of just how thoughtful and caring you are. It’s a moment I’ll always hold close to my heart.
			</p>

			<br/>

			<button className='myBtn' onClick={() => navigate('/fifth')}>Click to go to the fifth page</button>
		</div>
	)
}