import React, { useState, useEffect } from "react";
import firstPhoto from '../images/firstEverPhoto.jpg';
import frourio from '../images/frourio.jpg';
import gregory from '../images/gregory.jpg';
import savvato from '../images/savvato.jpg';
import lovePic from '../images/loveU.png';
import { useNavigate } from "react-router-dom";

export default function Game() {
  const navigate = useNavigate();
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    // Trigger the fade-in effect after the component mounts
    const timer = setTimeout(() => {
      setOpacity(1);
    }, 10); // A slight delay ensures smooth transition

    // Cleanup timer (optional but good practice)
    return () => clearTimeout(timer);
  }, []);

  // The images and texts that correspond to each other
  const images = [
    { src: firstPhoto, alt: "Image 1" },
    { src: frourio, alt: "Image 2" },
    { src: gregory, alt: "Image 3" },
    { src: savvato, alt: "Image 4" },
  ];

  const texts = [
    "In our most visited place.",
    "Our first ever selfie.",
    "Went for church, got ducks, geese and cats instead.",
    "Where you grew stronger emotionally regarding your mother.",
  ];

  // States to track the clicked image and text
  const [clickedImage, setClickedImage] = useState(null);
  const [clickedText, setClickedText] = useState(null);
  const [win, setWin] = useState(0);
  const [won, setWon] = useState(false);

  // Handle text click
  const handleTextClick = (index) => {
    setClickedText(index);
    checkMatch(index, clickedImage);
  };

  // Handle image click
  const handleImageClick = (index) => {
    setClickedImage(index);
    checkMatch(clickedText, index);
  };

  function checkWin(){
    if(win === 3){
      setWon(true);
      const love = document.getElementById('abs-div');
      love.style.opacity = 1;
      love.style.zIndex = 5;
      
      setTimeout(() => {
        navigate('/fourth');
      }, 3000);
    }
  }

  // Check if the text and image match
  const checkMatch = (textIndex, imageIndex) => {

	if(textIndex === 0 && imageIndex === 2)
		{
			document.getElementById(`text-${textIndex}`).style.opacity = 0;
      document.getElementById(`image-${imageIndex}`).style.opacity = 0;
			setClickedImage(null);
			setClickedText(null);
			setWin(prevWin => prevWin + 1);
			checkWin();
		}else

	if(textIndex === 1 && imageIndex === 0)
		{
			document.getElementById(`text-${textIndex}`).style.opacity = 0;
      document.getElementById(`image-${imageIndex}`).style.opacity = 0;
			setClickedImage(null);
			setClickedText(null);
			setWin(prevWin => prevWin + 1);
			checkWin();
		}else
		
	if(textIndex === 2 && imageIndex === 3)
		{
			document.getElementById(`text-${textIndex}`).style.opacity = 0;
      document.getElementById(`image-${imageIndex}`).style.opacity = 0;
			setClickedImage(null);
			setClickedText(null);
			setWin(prevWin => prevWin + 1);
			checkWin();
		}else

	if(textIndex === 3 && imageIndex === 1)
		{
			document.getElementById(`text-${textIndex}`).style.opacity = 0;
      document.getElementById(`image-${imageIndex}`).style.opacity = 0;	
			setClickedImage(null);
			setClickedText(null);
			setWin(prevWin => prevWin + 1);
			checkWin();
		}
  };


  return (
    <div
      className="game-container"
      style={{
        opacity: opacity,
        transition: "opacity 1s ease", // Smooth fade-in
      }}
    >
      <h1 id="win-text" className={won ? "win" : ""}>{won ? "You Won!" : "Welcome to the Game!"}</h1>
      {won ? '' : 
          <p className="p-container" style={{textAlign: "center"}}>
            Try mathcing the images with the corresponding text by clicking text first then image!
          </p>
      }


		<br/>

    <div id="abs-div">
      <img className="abs-img" src={lovePic} alt="love" />
    </div>

		<div className="grid-container">
        {/* Render images in 2x2 grid */}
        {images.map((image, index) => (
          <div
            key={index}
				    id={`image-${index}`}
            className='image-container'
            onClick={() => handleImageClick(index)}
          >
            <img className="myImg" src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>

      <div className="text-container">
        {/* Render texts */}
        {texts.map((text, index) => (
          <p
            key={index}
				    id={`text-${index}`}
            className='text-item'
            onClick={() => handleTextClick(index)}
          >
            {text}
          </p>
        ))}
      </div>

    </div>
  );
}
