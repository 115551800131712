import React from "react";
import { useState } from "react";
import Game from './Game';

export default function ThirdPage(){

	const [blurState, setBlurState] = useState([5, 5, 5, 5]);
	const [start, setStart] = useState(false);

	function handleClick(){
		setStart(true);
	}

	const handleUnblur = (index) => {
		const newBlurState = [...blurState];
		newBlurState[index] = 0; // Unblur the clicked paragraph
		setBlurState(newBlurState);
	};

	return(
		
		<div className="thirdPage">
			{start ? (
			<Game />
		) : (
			<div className="thirdPage">
				<h1 className="title" style={{borderBottom: '2px solid red'}}>And the dream becomes reality</h1>

<br/>

<p 
	className="p-container"
	style={{ filter: `blur(${blurState[0]}px)` }}
	onClick={() => handleUnblur(0)}
>
	It’s the 6th of October, a beautiful Sunday morning. After waking and walking to our meeting spot, I wait, 
	savoring the serenity of the day. Around me, the world stirs with gentle purpose—people, well-dressed, 
	likely on their way to church or their daily work; birds fill the crisp air with sweet songs; and the 
	golden sun casts its warm light over everything. And then, from a distance, I see you. You shine brighter 
	than the sun itself, my love. As you draw nearer, I’m powerless to stop the smile spreading 
	across my face, a smile born purely of your presence. In that moment, the world fades into a 
	soft blur, leaving only you, my dazzling, radiant muse—my eye candy, my heart's delight.
</p>

<br/>

<p
	className="p-container"
	style={{ filter: `blur(${blurState[1]}px)`}}
	onClick={() => handleUnblur(1)}
>
	The moment you came close, I couldn’t help myself—I wrapped you in a hug. Having you in my arms 
	instantly became my favorite thing in the world, and it still is to this day. After we greeted each 
	other, I led you to Saint Paraskevi’s church—a place you fell in love with at first sight. Humble, 
	yet stunning in its simplicity, it radiated a quiet beauty that matched the serenity of the moment. 
	Together, we attended the Divine Liturgy, and for a brief time, it felt like I was already in 
	paradise. Witnessing the miracle of Jesus Christ’s ultimate sacrifice for us sinners, offering 
	salvation and hope, was made even more profound by having you by my side. Your 
	enchanting presence completed me. I could feel your feminine energy harmonizing with 
	mine, like two souls perfectly intertwined.
</p>

<br/>

<p
	className="p-container"
	style={{ filter: `blur(${blurState[2]}px)`}}
	onClick={() => handleUnblur(2)}
>
	After the church, we went to Liston! We got our coffee and began happily sharing our thoughts 
	and experiences. We opened up about our faith—how we found the path and even the moments when we lost it. 
	From that day alone, even setting aside all the things I’m about to write next, I can confidently say 
	that imagining a future with you feels so natural. What I knew about you then wasn’t much, but it was 
	just enough to picture myself waking up and being greeted by your adorable morning face next to me.
</p>

<br/>

<p
	className="p-container"
	style={{ filter: `blur(${blurState[3]}px)`}}
	onClick={() => handleUnblur(3)}
>
	Of course! I made you another game. Everything to keep my Queen entertained. Just press the button below first
</p>

<br/>

		<button
			className="myBtn"
			onClick={handleClick}
			style={{
			transition: 'transform 0.3s ease, background-color 0.3s ease',
			}}
			onMouseEnter={(e) => (e.target.style.transform = 'scale(1.1)')}
			onMouseLeave={(e) => (e.target.style.transform = 'scale(1)')}
		>
			Start Game
		</button>

<br/>
			</div>
		)}
			
			
		</div>
	)
}