import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SecondPage() {
  const [blurState, setBlurState] = useState([5, 5, 5, 5, 5]); // Initial blur state for each paragraph

  const navigate = useNavigate();

  // Function to handle unblurring of a specific paragraph
  const handleUnblur = (index) => {
    const newBlurState = [...blurState];
    newBlurState[index] = 0; // Unblur the clicked paragraph
    setBlurState(newBlurState);
  };

  return (
    <div id='secondPage'>
      <h1 className='title' style={{ borderBottom: '3px solid cyan' }}>
        AND the journey continues...
      </h1>

      <br />

      <p
        className='p-container'
        style={{ filter: `blur(${blurState[0]}px)` }}
        onClick={() => handleUnblur(0)} // Unblur this paragraph when clicked
      >
        That day was nothing short of serendipitous! It perfectly encapsulated the unexpected beauty of
        what unfolded. In that moment, I caught a glimpse of your inner self, and it was nothing less than
        beautiful. You smiled at the way I spoke, nodding in agreement with everything I shared about God.
        In that moment, the words 'She believes in GOD!' echoed loudly in my mind, a revelation that stirred my soul.
        From that moment, my eyes got to witness true beauty..
      </p>

      <br />

      <p
        className='p-container'
        style={{ filter: `blur(${blurState[1]}px)` }}
        onClick={() => handleUnblur(1)} // Unblur this paragraph when clicked
      >
        At first, it began as a friendly debate, where I tried to guide the new 'friend' I had made, hoping to
        show him the only true path. But soon, my intention shifted—from merely trying to enlighten him to wanting
        to hear your voice, to witness your reactions. With every word you spoke, you not only added your thoughts
        but enriched my own. Truly, I tell you, that experience is unforgettable.
      </p>

      <br />

      <p
        className='p-container'
        style={{ filter: `blur(${blurState[2]}px)` }}
        onClick={() => handleUnblur(2)} // Unblur this paragraph when clicked
      >
        After the perfect meeting with you, I returned home, expecting to unwind and relax. But relaxation was the
        last thing I found. Instead, my mind kept drifting back to thoughts of you—your voice, the way you spoke.
        I wanted to message you, but hesitation held me back. Then, in that exact moment, my phone buzzed with a
        notification. It was from you: 'Σπύρο, ωραία ήταν σήμερα, να κανονίσουμε και άλλη φορά όταν βρούμε ευκαιρία, αν
        θες.' Those words were exactly what you wrote. To who? me.. a nobody.
      </p>

      <br />

      <p 
			className='p-container'
			style={{ filter: `blur(${blurState[3]}px)` }}
       	onClick={() => handleUnblur(3)} // Unblur this paragraph when clicked
		>
        Not long after that moment, I could never have imagined what would unfold in the next two days. I
        was about to go on a date with an extraordinary woman of God, a true hidden gem. And where would this date
        take place? To church! What amazed me even more was that you were the one who suggested it. My heart felt
        warm, and a wave of ease and excitement washed over me. In that moment, I finally understood why you began
        counting from that day. Yes, our souls had finally found what they had been searching for. We both knew—we
        had found our other half.
      </p>

      <br />

      <button className='myBtn' onClick={() => navigate('/third')}>Click to go to the third page</button>

		<br/>

    </div>
  );
}
